import React from 'react'

const UnsupportedBrowserPage = () => {
  return (
    <div className="unsupported__container">
      <div className="unsupported__text">
        <h5 className="unsupported__text--heading">This web browser is not supported by DataSeer</h5>
        <p className="unsupported__text--subheading">
          Please download{' '}
          <a href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">
            Google Chrome
          </a>{' '}
          and try to access{' '}
          <a href={window.location.href} target="_blank" rel="noopener noreferrer">
            DataSeer
          </a>{' '}
          through that browser
        </p>
      </div>
    </div>
  )
}

export default UnsupportedBrowserPage
